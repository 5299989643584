import './App.css';
import KGrid from './comps/K_Grid';

function App() {
  return (
    <div className="App">
      <KGrid />
    </div>
  );
}

export default App;
