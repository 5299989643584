import React from "react"

const KCell = ( {c_position, c_text, c_color, c_dissappear, c_selected, handleOnClick} ) => {
    return (
        <div className={"cell "+c_dissappear} id={"cell_"+c_position} style={{background: c_color, borderColor: c_selected===c_position?"orange":"white" }} onClick={()=>handleOnClick(c_position)}>
            {c_text}
        </div>
    )
}

export default KCell;