import React, { useState } from "react";

const KInfo = () =>{
    const [isToggled, setIsToggled] = useState(false);
    return (
        <div className={isToggled===true?"info-view-modal":"info-view"} onClick={()=>setIsToggled(!isToggled)} >
            {isToggled && <span>Move around the 3x3 grid with the arrow keys. Cards will dissapear as you move. Only one will remain.</span>}
            {!isToggled && <span>i</span>}
        </div>
    );
}

export default KInfo;