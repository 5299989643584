import React, { useState } from "react"
import KInfo from "./K_Info";
import KCell from "./K_Cell"

// 1, 5, 9, 5, 7
const KGrid = () => {
    const gridCells = [{"p":"1","t":"Start","c":"#030","d":1},
                       {"p":"2","t":" :-)  ","c":"#033","d":20},
                       {"p":"3","t":"(x__x)","c":"#AA0","d":15},
                       {"p":"4","t":"o____O","c":"#600","d":20},
                       {"p":"5","t":"^ __ ^","c":"#660","d":27},
                       {"p":"6","t":">-__-<","c":"#066","d":6},
                       {"p":"7","t":"Kanluz","c":"#0A0","d":29},
                       {"p":"8","t":"| __ |","c":"#F00","d":28},
                       {"p":"9","t":"  <3  ","c":"#00F","d":15}];   
    const [enabledCells, setEnabledCells] = useState(["1","2","3","4","5","6","7","8","9"]);
    const [selectedCell, setSelectedCell] = useState(1);
    const [moveCounter, setMoveCounter] = useState(0);
    const [hMessage, setHMessage] = useState("Use Arrow keys to pick a card");

    const resetEnabledCells =() => {
        setEnabledCells(["1","2","3","4","5","6","7","8","9"]);
    }

    const disableCells = () => {
        let tempEC = [];

        for (let c of gridCells)
            if (c.d>moveCounter+1){
                tempEC.push(c.p);
            }
        setEnabledCells(tempEC);
    }

    const checkSelectedClick = (clickedPosition) =>{
        let clickNum = Number(clickedPosition);
        if(moveCounter < 28) {
            // currentSelection -> movingTo
            if(clickNum!== selectedCell && enabledCells.includes(clickedPosition)){
                // console.log("moving to ",clickedPosition);
                switch (selectedCell) {
                    case 1:
                        //updisabled,left disabled down +3,right+1
                        if(clickedPosition!=='2' && clickedPosition!=='4'){
                            setHMessage("Horizontal and Vertical Moves Only");
                        }
                        else{
                            setHMessage(null);
                            setSelectedCell(clickNum);
                            setMoveCounter(moveCounter+1);    
                            disableCells();
                        }
                        break;
                    case 2:
                        //updisabled,left -1, down+3, right+1
                        if(clickedPosition!=='1' && clickedPosition!=='3' && clickedPosition!=='5'){
                            setHMessage("Horizontal and Vertical Moves Only");
                        }
                        else{
                            setHMessage(null);
                            setSelectedCell(clickNum);
                            setMoveCounter(moveCounter+1);    
                            disableCells();
                        }
                        break;
                    case 3:
                        //up dis, left -1, down+3, right dis
                        if(clickedPosition!== '2' && clickedPosition!=='6'){
                            setHMessage("Horizontal and Vertical Moves Only");
                        }
                        else{
                            setHMessage(null);
                            setSelectedCell(clickNum);
                            setMoveCounter(moveCounter+1);    
                            disableCells();
                        }
                        break;
                    case 4: 
                        if(clickedPosition!=='1' && clickedPosition!=='5' && clickedPosition!=='7'){
                            setHMessage("Horizontal and Vertical Moves Only");
                        }
                        else{
                            setHMessage(null);
                            setSelectedCell(clickNum);
                            setMoveCounter(moveCounter+1);    
                            disableCells();
                        }
                        break;
                    case 5:
                        if(clickedPosition!=='2' && clickedPosition!=='4' && clickedPosition!=='6' && clickedPosition!=='8'){
                            setHMessage("Horizontal and Vertical Moves Only");
                        }
                        else{
                            setHMessage(null);
                            setSelectedCell(clickNum);
                            setMoveCounter(moveCounter+1);    
                            disableCells();
                        }
                        break;
                    case 6:
                        if(clickedPosition!=='3' && clickedPosition!=='5' && clickedPosition!=='9'){
                            setHMessage("Horizontal and Vertical Moves Only");
                        }
                        else{
                            setHMessage(null);
                            setSelectedCell(clickNum);
                            setMoveCounter(moveCounter+1);    
                            disableCells();
                        }
                        break;
                    case 7:
                        if(clickedPosition!=='4' && clickedPosition!=='8'){
                            setHMessage("Horizontal and Vertical Moves Only");
                        }
                        else{
                            setHMessage(null);
                            setSelectedCell(clickNum);
                            setMoveCounter(moveCounter+1);    
                            disableCells();
                        }
                        break;
                    case 8:
                        if(clickedPosition!=='5' && clickedPosition!=='7' && clickedPosition!=='9'){
                            setHMessage("Horizontal and Vertical Moves Only");
                        }
                        else{
                            setHMessage(null);
                            setSelectedCell(clickNum);
                            setMoveCounter(moveCounter+1);    
                            disableCells();
                        }
                        break;
                    case 9:
                        if(clickedPosition!=='6' && clickedPosition!=='8'){
                            setHMessage("Horizontal and Vertical Moves Only");
                        }
                        else{
                            setHMessage(null);
                            setSelectedCell(clickNum);
                            setMoveCounter(moveCounter+1);    
                            disableCells();
                        }
                        break;
                    default:
                        console.log("reached default clicked:", clickedPosition, "& selected = ", selectedCell)
                        break;
                }
            }
            else{
                setHMessage("selected card is disabled");
            }
        }
        else {
            //reset
            setHMessage("New Game Started");
            setSelectedCell(1);
            setMoveCounter(0);
            resetEnabledCells();
        }   
    }

    onkeydown= (e) => {
        if(moveCounter < 28) {
            switch (e.key) {
                case 'ArrowRight': 
                    let limitR = [3,6,9]; //Arrow Key Right disabled for right edges
                    // ()doesnt work for 3,6,9
                    if(limitR.includes(selectedCell)){
                        setHMessage("Can't move right.");
                        break;
                    }
                    if(!enabledCells.includes((selectedCell+1).toString())){
                        setHMessage("attempted to move right");
                        break;
                    } else {
                        setHMessage(null);
                        setSelectedCell(selectedCell+1);
                        setMoveCounter(moveCounter+1);    
                        disableCells();
                    }
                    break;
                case 'ArrowLeft':
                    let limitL = [1,4,7]; //Arrow Key Left disabled for left edges
                    if(limitL.includes(selectedCell)){
                        setHMessage("Can't move left");
                        break;
                    }
                    // () doesnt work for 1, 4, 7
                    if(!enabledCells.includes((selectedCell-1).toString())){
                        setHMessage("attempted to move left");
                        break;
                    } else {
                        setHMessage(null);
                        setSelectedCell(selectedCell-1);
                        setMoveCounter(moveCounter+1);
                        disableCells();   
                    }     
                    break;
                case 'ArrowUp':
                    if(!enabledCells.includes((selectedCell-3).toString())){
                        setHMessage("Can't move up");
                        break;
                    } else {
                        setHMessage(null);
                        setSelectedCell(selectedCell-3);
                        setMoveCounter(moveCounter+1);
                        disableCells();
                    }
                    break;
                case 'ArrowDown':
                    if(!enabledCells.includes((selectedCell+3).toString())){
                        setHMessage("Can't move down");
                        break;
                    } else {
                        setHMessage(null);
                        setSelectedCell(selectedCell+3);
                        setMoveCounter(moveCounter+1);
                        disableCells();
                    }
                    break;
                default:
                    break;
            }
        }
        else {
            //reset
            setHMessage("New Game Started");
            setSelectedCell(1);
            setMoveCounter(0);
            resetEnabledCells();
        }   
    }

    return (
        <div className="grid-container">
            <div className="header"><KInfo /> <h1> Move # {moveCounter} <span>{hMessage?hMessage:"Make a Move"}</span> </h1></div>
            <div className="grid">
                {gridCells && gridCells.map(c =>(
                    <KCell key={c.p} c_position={c.p} c_text={c.t} c_color={c.c} c_dissappear={moveCounter>=c.d?"animate":""} c_selected={selectedCell.toString()} handleOnClick={checkSelectedClick}/>
                ))}
            </div>
        </div>
    )
}

export default KGrid;